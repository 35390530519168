<template>
  <div class="h5Detail">
    <template v-if="item">
      <h1 v-if="item.showTitle == 1">{{ item ? item.title : "" }}</h1>
      <h4 v-if="item.showDate == 1">{{ item ? item.createTime : "" }}</h4>
      <div class="read-box">
        <div class="read">阅读量:{{ item.browseCount }}</div>
      </div>
      <div class="content" v-html="item ? item.content : ''"></div>
    </template>
    <div v-else class="not-data">暂无数据</div>
    <div class="ln-op" v-if="isns">
      <div class="link" @click="onDetails(nsIndex + 1, 'next')">
        <!-- <a v-show="nextItem" href="javascript:void(0)">下一篇</a> -->
        <a href="javascript:void(0)" :class="{ 'not-page': !nextItem }">下一篇</a>
      </div>
      <div class="link" @click="onDetails(nsIndex - 1, 'last')">
        <!-- <a v-show="lastItem" href="javascript:void(0)">上一篇</a> -->
        <a href="javascript:void(0)" :class="{ 'not-page': !lastItem }">上一篇</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 0, // 0查询列表直接访问第一条详情  1直接按文章详情查询
      item: null,
      leftList: [],
      nextItem: null,
      lastItem: null,
      nsIndex: 0,
      isns: true,
      // detail: {
      //   title: "9地60个项目：我国金融科技创新监管试点全面落地",
      //   date: "时间：2020-08-27 17:00:25",
      //   content: "我是富文本",
      // },
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      this.type = this.$route.query.type;
      console.log("this.type ---- ", this.type)
      // 查询列表直接访问第一条详情
      if (this.type == 0) {
        this.getList(this.$route.query.sid);
        this.isns = false;
      } else if (this.type == 1) {
        //直接按文章详情查询
        this.getLeftArticle(this.$route.query.sid);
        this.isns = true;
      } else if (this.type == 2) {
        // 查询通知
        this.getRollList();
        this.isns = true;
      } else if (this.type == 3) {
        // 置顶通知
        this.getTopList();
        this.isns = true;
      } else if (this.type == 10) {
        // 无数据
        this.isns = false;
        this.item = null;
      } else if (this.type == 4) {
        // 轮播图
        this.getSliderList();
        this.isns = true;
      } else if (this.type == 5) {
        // 首页广告
        this.getAdvList();
        this.isns = true;
      }
    },
    // 查询右侧滚动通知
    async getRollList() {
      let res = await this.$api.home.noticeList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getDetail(this.$route.query.articleId); // 获取首次要展示的文章
    },
    // 查询置顶通知
    async getTopList() {
      let res = await this.$api.home.topList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getDetail(this.$route.query.articleId); // 获取首次要展示的文章
    },
    async getLeftArticle(id) {
      let res = await this.$api.second.leftArticle(id);
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getDetail(this.$route.query.articleId); // 获取首次要展示的文章
    },
    async getSliderList() {
      let res = await this.$api.home.sliderList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getDetail(this.$route.query.articleId); // 获取首次要展示的文章
    },
    async getAdvList() {
      let res = await this.$api.second.getAdvList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getDetail(this.$route.query.articleId); // 获取首次要展示的文章
    },
    async getDetail(id) {
      let res = await this.$api.second.articleDetail(id);
      if (res.status == 200) {
        this.item = res.data;
        this.buildLastNaxt();
      }
    },
    onDetails(index, type) {
      if (type == "next") {
        if (!this.nextItem) {
          return;
        }
      }
      if (type == "last") {
        if (!this.lastItem) {
          return;
        }
      }
      if (index < this.leftList.length && index >= 0) {
        window.scrollTo(0, 0);
        this.nsIndex = index;
        // this.item = this.leftList[index];
        this.getDetail(this.leftList[index].id); // 获取首次要展示的文章
        this.buildLastNaxt();
      } else {
        if (index <= 0) {
          this.lastItem = null;
        }
        if (index == this.leftList.length) {
          this.nextItem = null;
        }
      }
    },
    buildLastNaxt() {
      this.leftList.forEach((aitem, index) => {
        if (aitem.id == this.item.id) {
          this.nsIndex = index;
          if (index != 0) {
            this.lastItem = this.leftList[index - 1];
          } else {
            this.lastItem = null;
          }
          if (index + 1 < this.leftList.length) {
            this.nextItem = this.leftList[index + 1];
          } else {
            this.nextItem = null;
          }
        }
      });
    },
    async getList(id) {
      console.log("id ---", id)
      let res = await this.$api.second.articleList({
        id: id,
        pageNo: 1,
      });
      console.log("res ---1 ", res, this.item)
      if (res.status == 200) {
        if(res.data.records.length > 0) {
           this.getDetail(res.data.records[0].id); // 获取首次要展示的文章
        }
      }
    },
  },
};
</script>

<style lang="scss">
.not-page {
  color: #909399 !important;
}
.not-data {
  text-align: center;
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}
.h5Detail {
  padding-top: 65px;
  position: relative;
  .ln-op {
    display: flex;
    float: right;
    padding: 0 20px 20px 0;
    .link {
      margin-left: 15px;
      .not-a {
        color: #666666;
      }
      a {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
  h1 {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  h4 {
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 17px;
  }
  .content {
    padding: 20px;
    p {
      text-indent: 0 !important;
    }
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .read-box {
    position: relative;
    .read {
      position: absolute;
      top: 0;
      right: 15px;
      color: #666666;
      font-size: 12px;
    }
  }
}
</style>
